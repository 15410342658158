import React from 'react';
import { Popover } from '@headlessui/react';
import { StaticImage } from 'gatsby-plugin-image';

export const Header = ({ id = 'header', background = undefined }) => {
  return (
    <header id={id}>
      <Popover className="relative" style={{ background }}>
        <div className="flex items-center justify-center px-4 py-6 mx-auto max-w-7xl sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex items-center justify-center overflow-hidden text-gray-800 sm:justify-start lg:w-0 lg:flex-1">
            <a href="#" className="">
              <span className="sr-only">Kibroco</span>
              <StaticImage
                src="../images/kibroco_logo.jpeg"
                className="w-auto h-4 rounded "
                placeholder="none"
                alt="hero"
                objectFit="contain"
                backgroundColor="transparent"
              />
            </a>
          </div>
        </div>
      </Popover>
    </header>
  );
};
